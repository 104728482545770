<template>
  <div class="widget-result-container">
    <div class="widget-container-horizontal" v-if="layout==='horizontal'">

      <div class="row" style="margin-bottom: 16px">
        <div class="col-sm-9">

          <h3 class="widget-title">What is your home worth?</h3>
        </div>
        <div class="col-sm-3 text-right"><img :src="agent.logo" v-if="agent.logo"
                                              style="max-width: 200px; max-height: 50px" /></div>
      </div>
      <div class="row" style="margin-bottom: 32px">
        <div class="col-sm-9 main-text">

          {{ welcomeMessage() }}

        </div>
      </div>
      <div class="row inner-container">
        <div class="col-sm-6 left-side">
          <HeaderAgentForm :agent="agent" :contact="{}" no-border />
        </div>

        <div class="col-sm-6">
          <p class="get-free-text">Get your free home valuation</p>
          <ValuationForm :agent-id="agent.id" :loading="loading" @on-submit="handleCreateLead" ref="form-horizontal" />
        </div>
      </div>
    </div>

    <div class="widget-container-vertical" v-if="layout==='vertical'">


      <div class="row" style="margin-bottom: 16px">
        <div class="col-sm-12 text-center" style="margin-bottom: 16px;">
          <img :src="agent.logo" v-if="agent.logo"
               style="max-width: 200px; max-height: 50px" /></div>

        <div class="col-sm-12 text-center">

          <h3 class="widget-title">What is your home worth?</h3>
        </div>

      </div>

      <div class="row">
        <div class="col-sm-12 main-text">
          {{ welcomeMessage() }}
        </div>

      </div>
      <div class="row" style="margin-top: 32px; margin-bottom: 24px">
        <div class="col-sm-12 ">
          <p class="text-center get-free-text">Get your free home valuation</p>
        </div>
      </div>


      <div class="row" style="">
        <div class="col-sm-12 ">
          <ValuationForm no-submit-btn ref="form-vertical" layout="vertical" :agent-id="agent.id" :loading="loading" @on-submit="handleCreateLead" />
        </div>
      </div>


      <div class="row">
        <div class="col-sm-12">
          <HeaderAgentForm type="vertical" :agent="agent" :contact="{}" no-border />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 text-center">
          <button type="button" @click="handleCreateLeadVertical" class="btn btn-dark" :disabled="loading">Continue</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import AgentInfo from '@/components/AgentInfo';
import ValuationForm from '@/components/forms/ValuationForm';
import { mapState } from 'vuex';
import DbcApi from "../../api/dbc";
import HeaderAgentForm from '../../components/LandingResult/components/HeaderAgentForm';
import TrackingService from '../../api/tracking';

export default {
  props: {
    layout: String,
    // agent1: Object,
    // agent2: Object,
  },
  data: function () {
    return {
      loading: false,
      agent1: {},
      agent2: {},
    }
  },
  methods: {
    handleCreateLeadVertical(){
      this.$refs['form-vertical'].submitForm();
    },
    async handleCreateLead(contact) {
      const { id } = this.agent;

      this.loading = true;
      try {
        contact.agent_id = id;
        const savedContact = await DbcApi.createContact(contact);
        // on OK redirect to result page
        this.loading = false;
        if (savedContact) {
          // track
          TrackingService.addAgentNewLead(contact.agent_id, savedContact.id, savedContact).then();
          const href = `${window.location.protocol}//${window.location.host}/result/${savedContact.id}/0`;
          const wnd = window.open(href, "_blank");
          // wnd.focus();
        }
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    welcomeMessage: function () {
      let welcome = 'Hi!';

      if (this.agent1) {
        const PronounHandling = (this.agent1.firstname && this.agent2.firstname)
            ? ` We are ${this.agent1.firstname} ${this.agent1.lastname} & ${this.agent2.firstname} ${this.agent2.lastname}, and we `
            : ` my name is ${this.agent1.firstname || this.agent2.firstname}, and I `;

        welcome += PronounHandling;
        welcome += ' would like to offer you a complimentary home valuation and quick peek at the market activity near your home. ';
        // welcome += 'Register below to get your FREE valuation.';
      }
      return welcome;
    },
  },
  beforeMount() {
    const agentId = this.$route.params.id;
    // const agentId = this.agentId;

    this.$store.dispatch('Agent/loadAgentData', { agentId }, { root: true }).then(() => {
      // !!!!
      // this.$store.dispatch('Tracking/addWidgetView', { agentId: agentId, layout: this.normalizedLayout });
    });
  },

  components: {
    HeaderAgentForm,
    'AgentInfo': AgentInfo,
    'ValuationForm': ValuationForm,
  },
  watch: {
    agent(newAgent) {
      this.agent1 = newAgent;
      this.agent2 = newAgent;
    },
  },
  computed: {

    ...mapState({
                  agent: state => state.Agent.agent,
                }),

    normalizedLayout: function () {
      if (-1 !== ['horizontal', 'vertical'].indexOf(this.layout.toString().toLowerCase())) {
        return this.layout.toString().toLowerCase();
      }
      return 'horizontal';
    },
  },
  metaInfo: function () {
    if (this.agent1) {
      let agentName = `${this.agent1.firstname} ${this.agent1.lastname}`;
      agentName += this.agent2.firstname ? ` &amp; ${this.agent2.firstname} ${this.agent2.lastname}` : '';

      return {
        'meta': [
          { 'description': `Home Valuation provided by ${agentName}` },
          { 'og:title': `Home Valuation provided by ${agentName}` },
          { 'og:url': `https://www.homeval.com/w/${this.agent1.id}` },
          { 'og:description': decodeURI(this.agent1.profile_message) },
          { 'og:type': 'website' },
          { 'og:image': 'https://s3.amazonaws.com/ims-site/stock/samples/home-val-sample.png' },
          { 'og:site_name': `Home Valuation provided by ${agentName}` },
          { 'twitter:title': `Home Valuation provided by ${agentName}` },
          { 'twitter:url': `https://www.home-val.com/w/${this.agent1.id}` },
          { 'twitter:description': decodeURI(this.agent1.profile_message) },
          { 'twitter:card': 'summary' },
          { 'twitter:image': 'https://s3.amazonaws.com/ims-site/stock/samples/home-val-sample.png' },
        ],
        'link': [
          {
            'rel': 'image_src',
            'href': 'https://s3.amazonaws.com/ims-site/stock/samples/home-val-sample.png',
            'type': 'image/png',
          },
        ],
      };
    }
  },
};
</script>

<style scoped lang="scss">
.widget-result-container {

  .widget-container-horizontal, widget-container-vertical {
    background: #F7F7F7;
  }

}

.widget-title {
  /* font-family: 'Metro Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: #525151;
}

.get-free-text {
  /* H6 Paragraph Heading */

  /* font-family: 'Metro Sans'; */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */


  /* Theme Color 1 */

  color: #525151;
}

.main-text {
  /* DBC Styles/DBC Body Content */

  /* font-family: 'Metro Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* or 138% */


  /* Theme Color 1 */

  color: #525151;
}

.inner-container {
  .card {
    background: transparent !important;
  }
}

.widget-container-horizontal {
  width: fit-content;
  max-height: 1022px;
  padding: 40px;
  background: #F7F7F7;
}

.widget-container-vertical {
  width: fit-content;
  padding: 16px;
  max-width: 320px;
  background: #F7F7F7;
}

</style>
